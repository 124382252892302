import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "react-i18next";
import Layout from "../layout";
import VacancyPage from "../vacancies/common/vacancyPage";

const VacancyTemplate = ({ data }) => {
    const jobInfo = data.vacanciesJson;
    const imgSrc = data.allFile.edges[0].node.publicURL;

    const { t } = useTranslation();
    let lookingArr = [];
    let offerArr = [];
    for (let i = 0; ; i++) {
        if (
            t(`vacancy.${jobInfo.slug}.lookingForArr.${i}`) ===
            `vacancy.${jobInfo.slug}.lookingForArr.${i}`
        )
            break;
        lookingArr.push(t(`vacancy.${jobInfo.slug}.lookingForArr.${i}`));
    }
    for (let i = 0; ; i++) {
        if (
            t(`vacancy.${jobInfo.slug}.offerArr.${i}`) ===
            `vacancy.${jobInfo.slug}.offerArr.${i}`
        )
            break;
        offerArr.push(t(`vacancy.${jobInfo.slug}.offerArr.${i}`));
    }

    return (
        <Layout>
            <VacancyPage
                img={imgSrc}
                title={t(`vacancy.${jobInfo.slug}.title`)}
                description={t(`vacancy.${jobInfo.slug}.description`)}
                lookingForTitle={t(`vacancy.lookingForTitle`)}
                lookingForArr={lookingArr}
                offerTitle={t(`vacancy.offerTitle`)}
                offerArr={offerArr}
            />
        </Layout>
    );
};

export const query = graphql`
    query($slug: String!) {
        vacanciesJson(slug: { eq: $slug }) {
            slug
        }
        allFile(filter: { extension: { eq: "svg" }, name: { eq: $slug } }) {
            edges {
                node {
                    publicURL
                    name
                }
            }
        }
    }
`;

export default VacancyTemplate;
